<template>
    <div class="tr">
        <div class="th serial-number">序号</div>
        <div
            :class="['th', item.class ? item.class : 'auto']"
            v-for="(item, index) in columns.filter(item => item.show != false)"
            :key="index"
        >
            {{ item.title }}
        </div>
    </div>
    <div class="value-content" ref="valueContent">
        <div
            class="tr moved-tr"
            v-for="(trData, trIndex) in datas"
            :key="trIndex"
            :style="{
                background: trIndex % 2 != 0 && striped ? '#13e4e64a' : 'none',
                animation: canRun ? `${name} ${height / 2 / 40 + 15}s linear infinite` : 'none',
            }"
        >
            <div
                class="th serial-number"
                :style="{ opacity: trIndex < data?.length || canRun ? 1 : 0 }"
            >
                {{ (trIndex % data?.length) + 1 }}
            </div>
            <div
                v-for="(item, thIndex) in columns.filter(item => item.show != false)"
                :class="['th', item.class ? item.class : 'auto']"
                :key="thIndex"
                :style="{ opacity: trIndex < data?.length || canRun ? 1 : 0 }"
            >
                <slot :name="item.slot" :record="trData">
                    {{ trData[columns[thIndex].dataIndex] }}
                </slot>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent, onMounted, reactive, toRefs, ref, nextTick, watch, computed } from "vue";
export default defineComponent({
    props: ["columns", "data", "striped", "name"],
    setup(props) {
        const state = reactive({
            valueContent: ref(null),
            height: 0,
            canRun: computed(() => {
                return state.height / 2 > document.body.clientHeight * 0.4 - 68;
            }),
            datas: computed(() => {
                const datas = props?.data?.concat(props?.data);
                return datas;
            }),
        });

        watch(
            () => props.data,
            newVal => {
                if (newVal?.length) {
                    nextTick(() => {
                        setTimeout(() => {
                            let height = state.valueContent.offsetHeight;
                            state.height = height;
                            let style = document.styleSheets[0];
                            style.insertRule(
                                `@keyframes ${props?.name} {
                                100% {
                                  transform: translateY(${-height / 2}px);
                                }
                            }
                            `,
                                0
                            );
                        }, 1000);
                    });
                }
            }
        );

        return {
            ...toRefs(state),
            getColor: {
                WAIT_PRODUCING: "gray",
                PRODUCING: "#13e4e6",
                COMPLETED: "#00FF57",
                TERMINATED: "red",
                WAIT_PROCESSED: "#E34712",
                PROCESSED: "#5FD229",
            },
            getEventColor: {
                GENERAL: "#E1CF17",
                URGENT: "#FF8300",
                CRITICAL: "#E34712",
            },
        };
    },
});
</script>

<style lang="less" scoped>
::-webkit-scrollbar {
    display: none;
}
:deep(.ant-progress-line) {
    margin: 0 10px;
    .ant-progress-text {
        color: #fff;
    }
    .ant-progress-inner {
        background-color: rgba(255, 255, 255, 0.23);
    }
}
.value-content {
    overflow-y: scroll;
    animation-play-state: paused;
}
.tr {
    color: #fff;
    display: flex;
    width: 100%;

    .th {
        // background: url("../../assets/thback.png");
        background-repeat: no-repeat;
        background-size: 100% 100%;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 5px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        text-align: center;
        border-left: 0.5px solid rgba(0, 0, 0, 0.85);
        border-right: 0.5px solid rgba(0, 0, 0, 0.85);
        &.auto {
            display: inline-block;
            line-height: 40px;
            flex: 1;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        &.serial-number {
            width: 80px;
        }

        &.schedule,
        &.createdTime,
        &.productDate,
        &.lastReportTime,
        &.statusDesc,
        &.eventLevelDesc,
        &.creatorDisplayName {
            width: 150px;
        }
        &.code,
        &.quantity,
        &.completedCount {
            width: 200px;
        }
        &.containerNo {
            width: 200px;
        }
        &.progressPercent {
            width: 250px;
        }
        &.produceTeamName {
            width: 200px;
        }
    }
}
</style>
