<template>
    <div class="abnormal">
        <div class="title">
            <div>异常事件</div>
            <img src="../../assets/u25.svg" alt="" />
            <div class="line"></div>
        </div>
        <Table :columns="comColums" :data="data">
            <template v-slot:createdTime="{ record }">
                {{ formateMoment(record?.createdTime, "MM-DD HH:mm") }}
            </template>
        </Table>
    </div>
</template>

<script>
import { defineComponent, computed } from "vue";
import Table from "./Table.vue";
import { formateMoment } from "@/utils/common";
const columns = [
    {
        title: "事件名称",
        dataIndex: "eventName",
    },
    {
        title: "事件描述",
        dataIndex: "description",
    },

    {
        title: "事件状态",
        dataIndex: "statusDesc",
        class: "statusDesc",
    },
    {
        title: "上报时间",
        dataIndex: "createdTime",
        slot: "createdTime",
        class: "createdTime",
    },
];
export default defineComponent({
    props: ["data", "showEventLevel"],
    components: {
        Table,
    },
    setup(props) {
        return {
            columns,
            formateMoment,
            comColums: computed(() => {
                let _columns = [...columns];
                if (props.showEventLevel) {
                    _columns.splice(2, 0, {
                        title: "事件等级",
                        dataIndex: "eventLevelDesc",
                        class: "eventLevelDesc",
                    });
                }
                return _columns;
            }),
        };
    },
});
</script>

<style lang="less" scoped>
::-webkit-scrollbar {
    display: none;
}
.abnormal {
    height: 40vh;
    overflow: hidden;
    grid-area: g4;
    padding: 8px;
    position: relative;
    background: rgba(0, 0, 255, 0.09803921568627451);
    .title {
        color: #fff;
        position: relative;
        .line {
            background: rgba(255, 255, 255, 0.2);
            height: 2px;
            position: absolute;
            width: 100%;
            bottom: 6px;
        }
    }
}
</style>
