<template>
    <div class="total">
        <div class="numbers">
            <div class="box">
                <span class="value">
                    {{ numbers?.completeProductSumCount }}
                </span>
                <span class="name">当前完成产品总数</span>
            </div>
            <div class="box">
                <span class="value">{{ numbers?.todayCompleteProductCount }}</span>
                <span class="name">今日完成产品数</span>
            </div>
            <div class="box">
                <span class="value">{{ numbers?.todayWorkReportCount }}</span>
                <span class="name">今日报工次数</span>
            </div>
            <div class="box">
                <span class="value">
                    {{ numbers?.completeWorkOrderSumCount }}/{{
                        numbers?.noCompleteWorkOrderSumCount
                    }}
                </span>
                <span class="name">当前完成工单数/剩余工单数</span>
            </div>
            <div class="box" style="color: #6666ff">
                <span class="value">{{ numbers?.producingOrdersWorkReportSumCount }}</span>
                <span class="name">当前在制品数量</span>
            </div>
            <div class="box" style="color: #ff9f66">
                <span class="value">{{ numbers?.todayExcReportCount }}</span>
                <span class="name">今日异常数</span>
            </div>
            <div class="box" style="color: #ff9f66">
                <span class="value">{{ numbers?.producingOrdersExcReportSumCount }}</span>
                <span class="name">生产中订单异常总数</span>
            </div>
            <div class="box" style="color: #ff6666">
                <span class="value">
                    {{ numbers?.producingOrdersWaitProcessedExcReportSumCount }}
                </span>
                <span class="name">生产中未处理异常数</span>
            </div>
        </div>
        <div class="ordes">
            <div class="title">
                <div>车间生产订单进度展示</div>
                <img src="../../../../assets/u25.svg" alt="" />
                <div class="line"></div>
            </div>
            <div class="content">
                <div v-for="(item, index) in orders" :index="index" class="order-item">
                    <span class="name">{{ item?.projectName }}</span>
                    <a-progress
                        class="progress"
                        :stroke-color="{
                            from: '#13E4E6',
                            to: '#13E683',
                        }"
                        :percent="item.progressPercent"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// import * as echarts from "echarts";
import { defineComponent, nextTick, ref, watch } from "vue";
export default defineComponent({
    props: ["numbers", "orders", "process"],
    setup(props) {
        const echart = ref(null);
        const init = data => {
            let myChart = echarts.init(echart.value);
            myChart.setOption({
                legend: {
                    type: "scroll",
                    orient: "vertical",
                    right: 0,
                    textStyle: {
                        color: "#fff",
                    },
                },
                series: [
                    {
                        type: "pie",
                        radius: "50%",
                        center: ["20%", "50%"],
                        data: [],
                        label: {
                            show: false,
                        },
                        data,
                    },
                ],
            });
        };
        watch(
            () => props.process,
            newVal => {
                if (newVal) {
                    let data = Object.entries(newVal).map(item => ({
                        name: item[0].split(", ")[1].split("]")[0],
                        value: item[1],
                    }));
                    nextTick(() => {
                        // init(data);
                    });
                }
            }
        );
        return {
            echart,
        };
    },
});
</script>

<style lang="less" scoped>
::-webkit-scrollbar {
    display: none;
}
:deep(.order-item) {
    color: #fff;
    .name {
        display: inline-block;
        width: 180px !important;
    }
    .ant-progress-line {
        width: calc(100% - 180px);
        .ant-progress-inner {
            background-color: rgba(255, 255, 255, 0.23);
        }
    }
}
:deep(.ant-progress-text) {
    color: #fff;
}
.title {
    color: #fff;
    position: relative;
    .line {
        background: rgba(255, 255, 255, 0.2);
        height: 2px;
        position: absolute;
        width: calc(100% - 20px);
        bottom: 6px;
    }
}
.total {
    grid-area: g1;
    display: grid;
    grid-template-columns: 3fr 2fr;
    grid-gap: 8px;
    .numbers {
        height: 100%;
        display: grid;
        grid-template-rows: repeat(2, 1fr);
        color: #13e4e6;
        font-family: "Arial-BoldItalicMT", "Arial Bold Italic", "Arial", sans-serif;
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-gap: 8px;
        background: rgba(0, 0, 255, 0.09803921568627451);
        .box {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            .value {
                font-size: 25px;
                margin-bottom: 20px;
            }
        }
    }
    .title {
        font-weight: bold;
    }
    .ordes {
        height: 30vh;
        background: rgba(0, 0, 255, 0.09803921568627451);
        padding: 8px;
        box-sizing: border-box;
        min-width: 400px;
        .content {
            height: calc(100% - 30px);
            overflow-y: scroll;
            .order-item {
                display: flex;
                padding: 6px;
                box-sizing: border-box;
                .name {
                    display: inline-block;
                    flex: 1;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
                .progress {
                    flex: 2;
                    margin-left: 10px;
                }
            }
        }
    }
}
</style>
