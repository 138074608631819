<template>
    <div class="report">
        <div class="title">
            <div>报工记录（昨日-今日）</div>
            <img src="../../../../assets/u25.svg" alt="" />
            <div class="line"></div>
        </div>
        <Table :columns="columns" :data="data">
            <template v-slot:createdTime="{ record }">
                {{ formateMoment(record?.productionStartTime, "MM-DD HH:mm") }}
            </template>
        </Table>
    </div>
</template>

<script>
import { defineComponent } from "vue";
import Table from "../../Table.vue";
import { formateMoment } from "@/utils/common";
const columns = [
    {
        title: "工单号",
        dataIndex: "code",
        class: "code",
    },
    {
        title: "生产时间",
        dataIndex: "createdTime",
        slot: "createdTime",
        class: "createdTime",
    },
    {
        title: "生产班组",
        dataIndex: "produceTeamName",
        class: "produceTeamName",
    },
    {
        title: "提交人",
        dataIndex: "creatorDisplayName",
        class: "creatorDisplayName",
    },
    {
        title: "报工工序",
        dataIndex: "cjWorkOrderStepName",
    },
    {
        title: "生产数量",
        dataIndex: "completedCount",
        class: "completedCount",
    },
];
export default defineComponent({
    props: ["data"],
    components: {
        Table,
    },
    setup() {
        return {
            columns,
            formateMoment,
        };
    },
});
</script>

<style lang="less" scoped>
.report {
    height: 40vh;
    overflow: hidden;
    grid-area: g3;
    padding: 8px;
    position: relative;
    background: rgba(0, 0, 255, 0.09803921568627451);
    .title {
        color: #fff;
        position: relative;
        .line {
            background: rgba(255, 255, 255, 0.2);
            height: 2px;
            position: absolute;
            width: 100%;
            bottom: 6px;
        }
    }
}
</style>
