<template>
    <div class="container">
        <div class="bankname">
            <img src="../../../assets/left.svg" alt="" />
            <span class="name">车间生产综合看板</span>
            <img src="../../../assets/right.svg" alt="" />
        </div>
        <Total
            :numbers="data?.cjStartedOrderCountInfo"
            :orders="data?.cjStartedOrderInfos"
            :process="data?.cjStartedWorkOrderStepCodeAndName2Count"
        />
        <WorkOrder :data="data?.producingCjWorkOrderInfos" />
        <Report
            :data="
                data?.cjWorkReportInfos.map(item => ({
                    ...item,
                    ...item?.cjWorkOrderInfo,
                }))
            "
        />
        <Abnormal :data="data?.excReportInfos" :showEventLevel="true"/>
    </div>
</template>

<script>
import { defineComponent, reactive, toRefs } from "vue";
import Total from "./components/total.vue";
import WorkOrder from "./components/workOrder.vue";
import Report from "./components/report.vue";
import Abnormal from "../abnormal";
import { formateMoment } from "@/utils/common";
import { apiBigScreenWorkshopPanel } from "@/api";
export default defineComponent({
    components: {
        Total,
        WorkOrder,
        Report,
        Abnormal,
    },
    setup() {
        const state = reactive({
            data: null,
        });

        const getData = async () => {
            let res = await apiBigScreenWorkshopPanel();
            if (res.status === "SUCCESS") {
                state.data = res.data;
            }
        };
        getData();

        setInterval(() => getData(), 60000);

        return {
            ...toRefs(state),
            formateMoment,
        };
    },
});
</script>

<style lang="less" scoped>
::-webkit-scrollbar {
    display: none;
}
:deep(.ant-table) {
    background: rgba(0, 0, 255, 0.09803921568627451);
    color: #fff;
}
:deep(th) {
    background: transparent !important;
    color: #fff !important;
}
:deep(.ant-table-tbody > tr.ant-table-row:hover > td, .ant-table-tbody
        > tr
        > td.ant-table-cell-row-hover) {
    background: transparent !important;
}
.bankname {
    height: 80px;
    text-align: center;
    font-size: 30px;
    padding: 20px 0;
    box-sizing: border-box;
    font-family: "PingFangSC-Semibold", "PingFang SC Semibold", "PingFang SC", sans-serif;
    font-weight: 650;
    font-style: normal;
    font-size: 36px;
    color: #ffffff;
    grid-area: g0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    img {
        height: 100%;
        flex: 1;
        width: 0;
    }
    .name {
        display: inline-block;
        flex: 1;
    }
}
.container {
    overflow: scroll;
    height: 100vh;
    min-width: 1500px;
    background-color: #f0f2f5;
    display: grid;
    grid-template-areas:
        "g0 g0"
        "g1 g1"
        "g2 g2"
        "g3 g4";
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 8px;
    padding: 8px;
    background-image: url("../../../assets/back.png");
    background-size: 100% 100vh;
    background-repeat: no-repeat;
    .title {
        padding: 8px;
        color: #fff;
    }
}
</style>
