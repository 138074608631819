<template>
    <div class="workrder">
        <div class="title">
            <div>工单进度展示</div>
            <img src="../../../../assets/u25.svg" alt="" />
            <div class="line"></div>
        </div>
        <Table :columns="columns" :data="data" :striped="true">
            <template v-slot:progressPercent="{ record }">
                <a-progress :percent="record?.progressPercent" />
            </template>
            <template v-slot:quantity="{ record }">
                {{ record.passedReportCount }} / {{ record.taskQuantity }}
            </template>
        </Table>
    </div>
</template>

<script>
import { defineComponent } from "vue";
import Table from "../../Table.vue";
const columns = [
    {
        title: "工单号",
        dataIndex: "code",
        class: "code",
    },
    {
        title: "工单状态",
        dataIndex: "statusDesc",
        class: "statusDesc",
    },
    {
        title: "工单进度",
        dataIndex: "progressPercent",
        slot: "progressPercent",
        class: "progressPercent",
    },
    {
        title: "项目名称",
        dataIndex: "projectName",
    },
    {
        title: "产品名称",
        dataIndex: "productName",
    },
    {
        title: "已完成数量/任务数量",
        dataIndex: "quantity",
        class: "quantity",
    },
    {
        title: "生产班组",
        dataIndex: "produceTeamName",
        class: "produceTeamName",
    },
];
export default defineComponent({
    props: ["data"],
    components: {
        Table,
    },
    setup() {
        return {
            columns,
        };
    },
});
</script>

<style lang="less">
.workrder {
    height: 40vh;
    overflow: hidden;
    grid-area: g2;
    padding: 8px;
    position: relative;
    background: rgba(0, 0, 255, 0.09803921568627451);
    .title {
        color: #fff;
        position: relative;
        .line {
            background: rgba(255, 255, 255, 0.2);
            height: 2px;
            position: absolute;
            width: 100%;
            bottom: 6px;
        }
    }
}
</style>
